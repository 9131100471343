import { FormControl, MenuItem, Paper, TextField } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react';
import { Field, useFormikContext } from 'formik'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { SimpleAdvertiserForm } from '../advertisers/SimpleAdvertiserForm';
import useBackendApi from '../../hooks/useBackendApi'
import { severities } from '../../utils/enums/notifications/notificationsSeverities'
import notify from '../../utils/notifications/notify'
import { crudConstNames, crudConstPath } from '../../utils/enums/crudConst';

export function QuotationData({ errors, touched, advertisers, agencies, industries }) {
  //
  /* HOOKS */
  const { sendRequest } = useBackendApi()
  const { values, setFieldValue } = useFormikContext();
  const { t } = useTranslation(['translation', 'quotation'])
  const [isOpenedAdvertiserModal, setIsOpenedAdvertiserModal] = useState(false)
  const advertiserOptionRef = useRef(values.advertiser_id)
  const [advertiserOptions, setAdvertiserOptions] = useState(advertisers);

  //
  /* METHODS */
  const handleCloseAdvertiserModal = () => setIsOpenedAdvertiserModal(false)

  useEffect(() => {
    setAdvertiserOptions(advertisers);
  }, [advertisers]);

  useEffect(() => {
    advertiserOptionRef.current = values.advertiser_id
  }, [values.advertiser_id]);

  const handleSelectAdvertiserChange = (event) => {
    const { value } = event.target;
    if (value === crudConstNames.create) {
      setIsOpenedAdvertiserModal(true)
    } else {
      advertiserOptionRef.current = value
      setFieldValue('advertiser_id', value)
    }
  };

  const handleCreateAdvertiser = (values) => {
    const advertiserData = { advertiser: values }
    sendRequest(crudConstPath.advertiser.create, crudConstNames.post, {}, advertiserData).then((response) => {
      if (response.success) {
        // Crear opcion y seleccionarla
        const newAdvertiser = { id: response.message.id, name: values.name }
        setAdvertiserOptions([...advertiserOptions, newAdvertiser]);
        advertiserOptionRef.current = newAdvertiser.id
        setFieldValue('advertiser_id', newAdvertiser.id)

        // Notifica y cierra modal
        notify(t('created', { ns: 'advertiser' }), severities.success)
        setIsOpenedAdvertiserModal(false)
      }
    })
  }


  //
  /* RETURN */
  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 3 }} elevation={3}>
      <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: 2 }} fullWidth>
        <Field
          as={TextField}
          name='title'
          error={errors.title && touched.title}
          helperText={errors.title && touched.title ? errors.title : ''}
          type='title'
          id='title'
          label={`${t('title', { ns: 'quotation' })} *`}
          fullWidth
          sx={{ flex: 1 }}
          size='small'
        />
      </FormControl>
      <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: 2 }} fullWidth>
        <Field
          as={TextField}
          name='advertiser_id'
          id='advertiser_id'
          label={`${t('advertiser', { ns: 'quotation' })} *`}
          labelId='advertiser_id'
          placeholder={t('select_advertiser', { ns: 'advertiser' })}
          error={errors.advertiser_id && touched.advertiser_id}
          helperText={errors.advertiser_id && touched.advertiser_id ? errors.advertiser_id : ''}
          fullWidth
          select
          sx={{ flex: 1 }}
          size='small'
          value={advertiserOptionRef.current}
          onChange={handleSelectAdvertiserChange}
          >
          <MenuItem value=''>{t('select_advertiser', { ns: 'advertiser' })}</MenuItem> {/* Opción vacía */}
          <MenuItem value='new'>{t('new', { ns: 'advertiser' })}</MenuItem> {/* Opción nueva anunciante */}
          {advertiserOptions.map((option, index) => {
            const { id, name } = option
            return (
              <MenuItem key={index} value={id}>
                {name}
              </MenuItem>
            )
          })}
        </Field>

        <Field
          as={TextField}
          name='agency_id'
          id='agency_id'
          label={t('agency', { ns: 'quotation' })}
          labelId='agency_id'
          placeholder={t('select_agency', { ns: 'agency' })}
          fullWidth
          select
          sx={{ flex: 1 }}
          size='small'>
          <MenuItem value=''>{t('select_agency', { ns: 'agency' })}</MenuItem>
          {agencies.map((option, index) => {
            const { id, name } = option
            return (
              <MenuItem key={index} value={id}>
                {name}
              </MenuItem>
            )
          })}
        </Field>
      </FormControl>
      <Field
        as={TextField}
        name='comments'
        type='comments'
        sx={{ flex: 1 }}
        id='comments'
        label={t('comments', { ns: 'quotation' })}
        multiline
        rows={4}
      />
      <SimpleAdvertiserForm
        isOpened={isOpenedAdvertiserModal}
        closeModalHandler={handleCloseAdvertiserModal}
        onSubmit={handleCreateAdvertiser}
        industries={industries}
      >
      </SimpleAdvertiserForm>
    </Paper>
  )
}

QuotationData.propTypes = {
  errors: propTypes.object,
  touched: propTypes.object,
  advertisers: propTypes.array,
  agencies: propTypes.array,
  industries: propTypes.array
}
