import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Quotation } from '../../components/quotations/Quotation'
import { useBidsCartActions } from '../../hooks/actions/useBidsCartActions'
import { useMapFilterActions } from '../../hooks/actions/useMapFilterActions'
import useBackendApi from '../../hooks/useBackendApi'
import { crudConst, crudConstNames, crudConstPath } from '../../utils/enums/crudConst'
import { notifySuccess } from '../../utils/notifications/notify'

import '../../styles/Quotations.scss'

const { update } = crudConst
const url = crudConstPath.quotations.index
const getQuotation = (sendRequest, url) => sendRequest(url, crudConstNames.get)
const updateQuotation = async (editQuotationParams, url, sendRequest) => {
  return await sendRequest(url, crudConstNames.patch, {}, editQuotationParams)
}

export function QuotationEdit() {
  const { t } = useTranslation(['translation', 'quotation'])
  const { data, loading, sendRequest } = useBackendApi()
  const { id } = useParams()
  const quotationsUpdateUrl = url + `/${id}`
  const quotationsEditUrl = quotationsUpdateUrl + '/edit'
  const { clearMapFilterState } = useMapFilterActions()
  const { setBidsInCart, setPackagePricingInCart, setTotalsFromQuotationInCart } = useBidsCartActions()
  const quotation = data?.quotation
  const advertisers = data?.advertisers
  const agencies = data?.agencies
  const providers = data?.providers
  const industries = data?.industries

  useEffect(() => {
    getQuotation(sendRequest, quotationsEditUrl)
    clearMapFilterState()
  }, [])

  useEffect(() => {
    if (!(quotation === null || quotation === undefined)) {
      setBidsInCart(quotation.bids)
      setPackagePricingInCart(quotation.package_pricing)
      setTotalsFromQuotationInCart(quotation.total_leasing_price, quotation.total_installation_price, quotation.total_production_price, quotation.total_price)
    }
  }, [quotation])

  const handleUpdate = async (values, packagePricing, bidsCart) => {
    const editQuotationParams = {
      ...values,
      package_pricing: packagePricing,
      bids_attributes: bidsCart
    }

    // TODO:  hacer que createQuotation use otro SendRequest ya que al llamarlo se debe volver a llamar el
    // getQuotation, ya que usan la misma variable "data" para almacenar los resultados
    // refactorizar en todos los formularios con este problema
    const response = await updateQuotation(editQuotationParams, quotationsUpdateUrl, sendRequest)
    if (response) {
      getQuotation(sendRequest, quotationsEditUrl)
      notifySuccess(t('updated', { ns: 'quotation' }))
    } else {
      getQuotation(sendRequest, quotationsEditUrl)
    }
  }
  //
  /* RETURN */

  return !loading && quotation && advertisers && agencies ? (
    <Quotation
      submitAction={handleUpdate}
      quotation={quotation}
      providers={providers}
      mode={update}
      advertisers={advertisers}
      agencies={agencies}
      industries={industries}
    />
  ) : null
}
