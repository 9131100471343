/* eslint-disable new-cap */
import { useEffect, useRef } from 'react'

import { useMapFilterActions } from '../hooks/actions/useMapFilterActions'
import { getPaddedMapBounds } from '../utils/getPaddedMapBounds'
import { useTimeoutWraper } from './useTimeoutWraper'

const fitMapToBounds = (showAllMarkers, map, markers) => {
  if (showAllMarkers) {
    const bounds = markers.reduce((acc, marker) => {
      return acc.extend(marker)
    }, new window.google.maps.LatLngBounds())

    map?.fitBounds(bounds)
  }
}

/**
 * useMapInitializer is use for initial configuration of googlemap
 * like onLoadLogic, onTilesLoad and onLoadMarker
 * @param {Boolean} showAllMarkers
 * @param {Array} markers
 * @returns
 */
export function useMapInitializer(showAllMarkers, markers, handleMarkerClick, onBoundsChanged, handleSetZoom) {
  const mapRef = useRef(null)
  const { setBoundsFilterState } = useMapFilterActions() // setBound to redux

  const timeoutWrapper = useTimeoutWraper()

  useEffect(() => {
    onBoundsChanged && mapRef.current && onBoundsChanged(mapRef.current.getBounds())
  }, [markers])

  const handleOnLoad = (map) => {
    mapRef.current = map
    // loadSpiderfyToMap(omsRef, mapRef, handleMarkerClick)
    fitMapToBounds(showAllMarkers, mapRef.current, markers)
  }

  const handleOnTilesLoad = () => {
    if (onBoundsChanged === undefined) return false

    timeoutWrapper(() => {
      const bounds = getPaddedMapBounds(mapRef?.current)
      setBoundsFilterState(bounds.toJSON())
      handleSetZoom(mapRef.current.getZoom())
    }, 1000)
  }

  return { handleOnLoad, handleOnTilesLoad, mapRef }
}
