/**
 * Normalizes an icon name by removing accents, converting to lowercase, 
 * and replacing spaces and underscores with hyphens.
 * 
 * @param {string} name - The original icon name to be normalized.
 * @returns {string} The normalized icon name.
 */
export function normalizeIconName(name) {
  if (!name) {
    return ''; // Returns an empty string if the name is null or undefined
  }

  return name
    .normalize("NFD")  // Decomposes accented characters into their base characters
    .replace(/[\u0300-\u036f]/g, "")  // Removes diacritics (accents)
    .toLowerCase()  // Converts the name to lowercase
    .replace(/\s+/g, ' ')  // Replaces multiple continuous spaces with a single space
    .replace(/(\s-\s)|(\s-)|(-\s)/g, '-')  // Replaces ' - ', ' -', and '- ' with '-'
    .replace(/\s+/g, '-')  // Replaces any remaining spaces with hyphens
    .replace(/_/g, '-');  // Replaces underscores with hyphens
}
