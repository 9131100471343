import EditIcon from '@mui/icons-material/Edit'
import { Button, Paper } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { EasyTable } from '../../components/EasyTable'
import { LoadingBox } from '../../components/shared/LoadingBox'
import useBackendApi from '../../hooks/useBackendApi'
import translatedHeaders from '../../utils/translatedHeaders'
import { crudConstNames, crudConstPath } from '../../utils/enums/crudConst'

const url = crudConstPath.advertiser.index

const attrs = [
  'name',
  'national_id',
  'corporate_name',
  'trade_name',
  'address',
  'email',
  'created_at',
  'updated_at'
]

export function AdvertiserIndex() {
  //
  /* HOOKS */
  const { t } = useTranslation(['translation', 'advertiser'])
  const navigate = useNavigate()
  const { data, loading, sendRequest } = useBackendApi()
  const advertisers = data?.advertisers
  const headers = translatedHeaders(attrs, 'advertiser')

  const actions = [
    {
      label: t('edit', { ns: 'advertiser' }),
      onClick: (id) => navigate(`${id}/edit`),
      icon: <EditIcon />,
      attrId: 'id'
    }
  ]

  useEffect(() => {
    sendRequest(url, crudConstNames.get)
  }, [])

  const handleClick = () => {
    navigate('/advertisers/new')
  }

  //
  /* RETURN */
  if (loading) return <LoadingBox/>
  return advertisers ? (
    <Paper
      elevation={0}
      sx={{
        p: 1,
        mt: 2,
        mr: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
      }}>
      <Button
        sx={{ maxWidth: '200px', marginLeft: 'auto' }}
        type='submit'
        color='secondary'
        variant='contained'
        onClick={handleClick}>
        {t('new', { ns: 'advertiser' })}
      </Button>
      <EasyTable headers={headers} data={advertisers} actions={actions} />
    </Paper>
  ) : null
}
