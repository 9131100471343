import ClearIcon from '@mui/icons-material/Clear'
import { FormControl, TextField, IconButton } from '@mui/material'
import { Field, useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

export const CodeFilterInput = () => {
  const { t } = useTranslation(['translation', 'adSpace'])
  const { setFieldValue, values } = useFormikContext()

  const handleClear = () => {
    setFieldValue('sku_filter', '')
  }

  return (
    <FormControl className='form-control-filters form-input' variant='outlined' size='small' fullWidth>
      <Field
        as={TextField}
        name='sku_filter'
        id='sku_filter'
        label={t('search_by_code', { ns: 'adSpace' })}
        fullWidth
        size='small'
        InputProps={{
          endAdornment: values.sku_filter ? (
            <IconButton onClick={handleClear} size='small'>
              <ClearIcon fontSize='small' />
            </IconButton>
          ) : null
        }}
      />
    </FormControl>
  )
}
