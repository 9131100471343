import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  Grid,
  Button
} from '@mui/material'
import { useJsApiLoader } from '@react-google-maps/api'
import propTypes from 'prop-types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { EasyTable } from '../../components/EasyTable'
import { AdSpaceDetails } from '../../components/adSpaces/AdSpaceDetails'
import { Map } from '../../components/map/Map'
import { QuotationBidCard } from '../../components/quotations/QuotationBidCard'
import { QuotationSummary } from '../../components/quotations/QuotationSummary'
import { useGetQuotation } from '../../hooks/useGetQuotation'
import { extractMarkersFromBids } from '../../utils/bid/extractMarkersFromBids'
import { detailsVariants } from '../../utils/detailsVariants'
import { durationUnits } from '../../utils/durationUnits'
import { libraries } from '../../utils/mapVariables'
import translatedHeaders from '../../utils/translatedHeaders'

import '../../styles/quotations/QuotationShow.css'
import { BidsTableWithPackageprice } from '../../components/bids/BidsTableWithPackagePrice'

const attrs = [
  'id',
  'ad_space',
  'monthly_price',
  'quantity',
  'duration',
  'leasing_price',
  'production_price',
  'installation_price',
  'total_price'
]

const attrs_without_monthly_price = [
  'id',
  'ad_space',
  'quantity',
  'duration',
  'leasing_price',
  'production_price',
  'installation_price',
  'total_price'
]

const summaryAttrs = ['total_leasing_price', 'total_production_price', 'total_installation_price', 'total_price']

const TABS = {
  GALLERY: 'cards gallery',
  TABLE: 'table info'
}

export function QuotationShow({ publicPage = false }) {
  const { id } = useParams()
  const { quotation, bids, loading } = useGetQuotation(id)
  const bidsHeaders = quotation.package_pricing ? translatedHeaders(attrs_without_monthly_price, 'bid') : translatedHeaders(attrs, 'bid')
  const summaryFooter = translatedHeaders(summaryAttrs, 'quotation')
  const detailsVariant = publicPage ? detailsVariants.public : detailsVariants.default
  const [selectedAdSpaceId, setSelectedAdSpaceId] = useState(null)
  const [isOpened, setIsOpened] = useState(false)
  const [tabSelector, setTabSelector] = useState(TABS.GALLERY)
  const [activeMarkerUuid, setActiveMarkerUuid] = useState({ uuid: null, active: false })
  const { t } = useTranslation(['translation', 'adSpace', 'quotation'])

  const handleInfoClick = (bid) => {
    setSelectedAdSpaceId(bid.ad_space_uuid)
    setIsOpened(true)
  }

  const switchTabSelector = (tab) => setTabSelector(tab)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries
  })
  const markers = extractMarkersFromBids(bids)

  const handleCloseModal = () => setIsOpened(false)

  const tableBidsData = () => {
    return bids.map((bid, index) => {
      return {
        id: index + 1,
        ad_space: (
          <Link
            key={index}
            onClick={() => {
              handleInfoClick(bid)
            }}>
            {bid.ad_space_name}
          </Link>
        ),
        monthly_price: t('currency', { val: bid.leasing_price, ns: 'adSpace' }),
        quantity: bid.quantity,
        duration: `${bid.duration}  ${durationUnits[bid.duration_unit]}`,
        leasing_price: t('currency', { val: quotation.package_pricing ? quotation.total_leasing_price : bid.total_leasing_price, ns: 'adSpace' }),
        production_price: t('currency', { val: quotation.package_pricing ? quotation.total_production_price : bid.production_price, ns: 'adSpace' }),
        installation_price: t('currency', { val: quotation.package_pricing ? quotation.total_installation_price : bid.installation_price, ns: 'adSpace' }),
        total_price: t('currency', { val: quotation.package_pricing ? quotation.total_price : bid.total_price, ns: 'adSpace' })
      }
    })
  }

  if (loading || !bids || bids.length === 0) return <div>Loading...</div>

  return (
    <>
      <Box className='container'>
        <Box className='info'>
          <Paper className='header-info' variant={'outlined'}>
            <QuotationSummary quotation={quotation} publicPage={publicPage} />
          </Paper>
        </Box>
        <Paper className='quotation-show-container'>
          <Box className='quotation-buttons-container'>
            <Button
              onClick={() => switchTabSelector(TABS.GALLERY)}
              size='small'
              variant={tabSelector === TABS.TABLE ? 'outlined' : 'contained'}
              color='secondary'
              sx={{ mr: 1 }}>
              {t('supports_sheet', { ns: 'quotation' })}
            </Button>
            <Button
              onClick={() => switchTabSelector(TABS.TABLE)}
              size='small'
              variant={tabSelector === TABS.GALLERY ? 'outlined' : 'contained'}
              color='secondary'>
              {t('commercial_proposal', { ns: 'quotation' })}
            </Button>
          </Box>
          <Box>
            {tabSelector === TABS.GALLERY && (
              <Box className='quotation-card-container'>
                {isLoaded ? (
                  <Box className='quotation-map-container'>
                    <Map
                      markers={markers}
                      showAllMarkers={true}
                      variant={detailsVariant}
                      activeMarkerUuid={activeMarkerUuid}
                    />
                  </Box>
                ) : null}
                <Grid container spacing={2} className='quotation-grid-container'>
                  {bids.map((bid, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index} style={{ display: 'flex' }}>
                      <QuotationBidCard
                        bid={bid}
                        onMouseEnter={(uuid) => setActiveMarkerUuid({ uuid, active: true })}
                        onMouseLeave={(uuid) => setActiveMarkerUuid({ uuid, active: false })}
                        detailsVariant={detailsVariant}
                        publicPage={publicPage}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
            {tabSelector === TABS.TABLE && (
              <Box className='ad-spaces'>
                {
                  bids ?
                    quotation.package_pricing ? <BidsTableWithPackageprice headers={bidsHeaders} data={tableBidsData()} /> : <EasyTable headers={bidsHeaders} data={tableBidsData()} />
                  : null
                }
                {
                  !quotation.package_pricing ?
                    <TableContainer component={Paper} sx={{ width: 'auto' }}>
                      <Table size='small'>
                        <TableHead>
                          <TableRow>
                            {Object.values(summaryFooter).map((header) => (
                              <TableCell key={header} align='center'>
                                {header}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          <TableRow>
                            {Object.keys(summaryFooter).map((key) => {
                              return (
                                <TableCell key={key} align='center'>
                                  {t('currency', { val: quotation[key], ns: 'adSpace' })}
                                </TableCell>
                              )
                            })}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer> : null
                }
              </Box>
            )}
          </Box>
        </Paper>
      </Box>
      {selectedAdSpaceId && isOpened && (
        <AdSpaceDetails
          variant={detailsVariant}
          isOpened={isOpened}
          closeModal={handleCloseModal}
          id={selectedAdSpaceId}
        />
      )}
    </>
  )
}

QuotationShow.propTypes = {
  publicPage: propTypes.bool
}
