import { crudConst } from '../../utils/enums/crudConst'
import { Button, ButtonGroup, FormControl, MenuItem, Paper, Select, TextField, Typography } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import propTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import MaskedInput from 'react-text-mask'
import * as Yup from 'yup'

const { create, update } = crudConst

export const AgencyForm = ({ onSubmit, agency }) => {
  //
  /* CONSTANTS */
  const action = agency ? update : create
  //
  /* HOOKS */
  const navigate = useNavigate()
  const { t } = useTranslation(['translation', 'agency'])

  //
  /* METHODS */
  const handleSubmit = (values) => {
    onSubmit(values)
  }

  const handleGoBack = () => {
    navigate('/agencies')
  }

  //
  /* RETURN */
  return (
    <>
      <Formik
        initialValues={{
          name: agency?.name || '',
          corporate_name: agency?.corporate_name || '',
          national_id: agency?.national_id || '',
          trade_name: agency?.trade_name || '',
          address: agency?.address || '',
          email: agency?.email || '',
        }}
        onSubmit={handleSubmit}
        validationSchema={Yup.object({
          name: Yup.string().required(t('name_required', { ns: 'agency' })),
          corporate_name: Yup.string().nullable(),
          national_id: Yup.string()
            .required(t('national_id_required', { ns: 'agency' }))
            .matches(/\b[0-9]{1,3}\.[0-9]{3}\.[0-9]{3}-[0-9kK]\b/, t('national_id_match', { ns: 'agency' })),
          address: Yup.string().nullable(),
          trade_name: Yup.string().nullable(),
          email: Yup.string()
            .email(t('email_invalid', { ns: 'agency' }))
            .nullable(),
          industry_id: Yup.string()
        })}>
        {({ errors, touched }) => (
          <Paper
            sx={{
              p: 1,
              mt: 2,
              mr: 2,
              flexDirection: 'column',
              gap: '10px',
              display: 'flex'
            }}
            elevation={3}>
            <Typography variant='h5' sx={{ color: 'secondary.dark', height: '25px', margin: 2 }}>
              {action === create ? t('new', { ns: 'agency' }) : t('edit', { ns: 'agency' })}
            </Typography>

            <Form sx={{ display: 'flex', gap: '10px' }}>
              <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: '10px', mb: '10px' }}>
                <Field
                  as={TextField}
                  error={errors.name && touched.name}
                  helperText={errors.name && touched.name ? errors.name : ''}
                  name='name'
                  id='name'
                  label={t('name', { ns: 'agency' })}
                  fullWidth
                  sx={{ flex: 1 }}
                  size='small'
                />
                <Field name='national_id'>
                  {({ field }) => (
                    <MaskedInput
                      {...field}
                      mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /[0-9k]/]}
                      guide={false}
                      render={(ref, inputProps) => (
                        <TextField
                          {...inputProps}
                          label={t('national_id', { ns: 'agency' })}
                          error={errors.national_id && touched.national_id}
                          helperText={errors.national_id && touched.national_id ? errors.national_id : ''}
                          id='national_id'
                          size='small'
                          fullWidth
                          sx={{ flex: 1 }}
                          inputRef={ref}
                        />
                      )}
                    />
                  )}
                </Field>
              </FormControl>

              <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: '10px', mb: '10px' }}>
                <Field
                  as={TextField}
                  error={errors.corporate_name && touched.corporate_name}
                  helperText={errors.corporate_name && touched.corporate_name ? errors.corporate_name : ''}
                  name='corporate_name'
                  id='corporate_name'
                  label={t('corporate_name', { ns: 'agency' })}
                  fullWidth
                  sx={{ flex: 1 }}
                  size='small'
                />

                <Field
                  as={TextField}
                  name='trade_name'
                  id='trade_name'
                  label={t('trade_name', { ns: 'agency' })}
                  sx={{ flex: 1 }}
                  size='small'
                />
              </FormControl>

              <FormControl sx={{ display: 'flex', flexDirection: 'row', gap: '10px', mb: '10px' }}>
                <Field
                  as={TextField}
                  error={errors.email && touched.email}
                  helperText={errors.email && touched.email ? errors.email : ''}
                  name='email'
                  type='email'
                  id='email'
                  label={t('email', { ns: 'agency' })}
                  fullWidth
                  sx={{ flex: 1 }}
                  size='small'
                />

                <Field
                  as={TextField}
                  name='address'
                  id='address'
                  label={t('address', { ns: 'agency' })}
                  sx={{ flex: 1 }}
                  size='small'
                />
              </FormControl>

              <ButtonGroup
                variant='outlined'
                aria-label='outlined button group'
                sx={{ display: 'flex', justifyContent: 'right', alignItems: 'right', gap: '10px' }}>
                <Button color='primary' type='button' variant='contained' onClick={handleGoBack}>
                  {t('back_to_list', { ns: 'agency' })}
                </Button>
                <Button color='secondary' type='submit' variant='contained'>
                  {action === update ? t('save', { ns: 'translation' }) : t('create', { ns: 'agency' })}
                </Button>
              </ButtonGroup>
            </Form>
          </Paper>
        )}
      </Formik>
    </>
  )
}

AgencyForm.propTypes = {
  submitAction: propTypes.func,
  agency: propTypes.shape({
    id: propTypes.number,
    name: propTypes.string,
    corporate_name: propTypes.string,
    national_id: propTypes.string,
    trade_name: propTypes.string,
    address: propTypes.string,
    email: propTypes.string,
  })
}
