import { bidPricesTypes } from "../enums/bidPrices"
import { calculateLeasingPrice } from "./calculateLeasingPrice"
import { calculateLeasingTotal } from "./calculateLeasingTotal"

const FROM_BASE_PRICE = true

export const getPriceByAttribute = (
  bid,
  attribute,
  totalPrice,
  baseTotalPrice,
  isBaseTotalZero
) => {
  let newPrice
  let newLeasingPrice
  switch (attribute) {
    case bidPricesTypes.leasing:
      newPrice = baseTotalPrice > 0 ? totalPrice / baseTotalPrice * calculateLeasingTotal(bid, FROM_BASE_PRICE) : 0
      newLeasingPrice = calculateLeasingPrice(bid, newPrice)
      return { [attribute]: Math.round(newLeasingPrice), total_leasing_price: Math.round(newPrice) }
    case bidPricesTypes.production:
      if (isBaseTotalZero) {
        newPrice = baseTotalPrice
      } else {
        newPrice = totalPrice / baseTotalPrice * bid[bidPricesTypes.base_production_price] * bid.quantity
      }
      return { [attribute]: Math.round(newPrice) }
    case bidPricesTypes.installation:
      if (isBaseTotalZero) {
        newPrice = baseTotalPrice
      } else {
        newPrice = totalPrice / baseTotalPrice * bid[bidPricesTypes.base_installation_price] * bid.quantity
      }
      return { [attribute]: Math.round(newPrice) }
  }
}
