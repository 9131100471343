import AddIcon from '@mui/icons-material/Add'
import { Button, Paper } from '@mui/material'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from '../../hooks/store'
import { crudConst } from '../../utils/enums/crudConst'
import { usePreCampaignRequester } from '../../hooks/campaigns/usePreCampaignRequester'

export function QuotationFormActions({ action, submitAction, formRef, quotationId = 0, isQuotationForm }) {
  const { t } = useTranslation(['translation', 'quotation'])
  const { create } = crudConst
  const bidsCart = useAppSelector((state) => state.bidsCart)
  const { sendPreCampaignRequest } = usePreCampaignRequester()

  const handleSubmitQuotationForm = () => {
    submitAction(formRef.current.values, bidsCart.packagePricing, bidsCart.bids)
  }

  const handleGeneratePreCampaign = (quotationId) => {
    sendPreCampaignRequest(quotationId)
  }



  return (
    <Paper
      sx={{ display: 'flex', flexDirection: 'row', gap: '5px', p: '5px', alignItems: 'end', justifyContent: 'end' }}>
      <Button
        color='secondary'
        type='button'
        onClick={() => {
          handleSubmitQuotationForm()
        }}
        variant='contained'>
        {action === create ? t('create', { ns: 'quotation' }) : t('save', { ns: 'translation' })}
      </Button>

      {/* @Note: Hidden create campaign temporarily */}
      {
        isQuotationForm ?
        <Button
          onClick={() => handleGeneratePreCampaign(quotationId)}
          variant='contained'
          startIcon={<AddIcon />}
          sx={{ mr: '8px' }}>
          {t('create_campaign', { ns: 'quotation' })}
        </Button> : null
      }
      {/* SALE_MODULE: hidden temporarily */}
      {/* {action === create ? (
        <Button color='primary' type='button' variant='contained' disabled>
          {t('generate', { ns: 'sale' })}
        </Button>
      ) : (
        <Button
          color='primary'
          type='button'
          variant='contained'
          onClick={() => {
            handleGenerateSale(quotationId)
          }}>
          {t('generate', { ns: 'sale' })}
        </Button>
      )} */}
    </Paper>
  )
}

QuotationFormActions.propTypes = {
  action: PropTypes.number,
  submitAction: PropTypes.func.isRequired,
  quotationId: PropTypes.number,
  formRef: PropTypes.object,
  isQuotationForm: PropTypes.bool
}
