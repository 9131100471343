import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import PreviewIcon from '@mui/icons-material/Preview'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Paper } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { severities } from '../../utils/enums/notifications/notificationsSeverities'
import notify from '../../utils/notifications/notify'

import { EasyTable } from '../../components/EasyTable'
import { ConfirmationModal } from '../../components/shared/ConfirmationModal'
import useBackendApi from '../../hooks/useBackendApi'
import { useDestroyRecord } from '../../hooks/useDestroyRecord'
import { useReplicateRecord } from '../../hooks/useReplicateRecord'
import { crudConstNames, crudConstPath } from '../../utils/enums/crudConst'
import { formattedSaleListTotalPrices } from '../../utils/formattedSaleListTotalPrices'
import translatedHeaders from '../../utils/translatedHeaders'

const url = crudConstPath.quotations.index
const attrs = ['serial', 'title', 'advertiser_name', 'user_name', 'created_at', 'updated_at', 'total_price']

export function QuotationIndex() {
  const { t } = useTranslation(['translation', 'quotation'])
  const [isOpened, setIsOpened] = useState(false)
  const [fetchData, setFetchData] = useState(true)
  const [activeQuotationId, setActiveQuotationId] = useState(null)
  const { destroyRecord } = useDestroyRecord()
  const { replicateRecord } = useReplicateRecord()
  const { data, sendRequest } = useBackendApi()
  const headers = translatedHeaders(attrs, 'quotation')
  const navigate = useNavigate()

  const actions = [
    {
      label: t('view', { ns: 'translation' }),
      onClick: (id) => navigate(`${id}`),
      icon: <PreviewIcon />,
      attrId: 'uuid'
    },
    {
      label: t('edit', { ns: 'translation' }),
      onClick: (id) => navigate(`${id}/edit`),
      icon: <EditIcon />,
      attrId: 'id'
    },
    {
      label: t('destroy', { ns: 'quotation' }),
      onClick: (id) => handleOnClickDestroy(id),
      icon: <DeleteIcon />,
      attrId: 'id'
    },
    {
      label: t('more-options', { ns: 'translation' }),
      icon: <MoreVertIcon/>,
      options: [
        {
          label: t('replicate', { ns: 'quotation' }),
          onClick: (id) => handleOnClickReplicate(id),
          attrId: 'id'
        },
      ]
    }
    // SALE_MODULE: hidden temporarily
    // ,{
    //   label: t('confirm_sale_short', { ns: 'sale' }),
    //   onClick: (id) => navigate(`/sales/${id}/confirmation`),
    //   icon: <ShopIcon />,
    //   attrId: 'id'
    // }
  ]
  const quotations = data?.quotations

  const handleOnClickReplicate = async (id) => {
    try {
      const response = await replicateRecord(crudConstPath.quotations.replicate(id), t('replicate_success', { ns: 'quotation' }))
      navigate(`/quotations/${response.message.id}/edit`)
      notify(t('replicate_success', { ns: 'quotation' }), severities.success)

    } catch (error) {
      console.warn(error)
    }
  }

  const handleCloseModal = () => setIsOpened(false)
  const handleOnClickDestroy = (id) => {
    setIsOpened(true)
    setActiveQuotationId(id)
  }
  const handleDestroy = (id) => {
    destroyRecord(id, url, t('destroy_success', { ns: 'quotation' }))
    setFetchData(true)
    setActiveQuotationId(null)
    setIsOpened(false)
  }

  useEffect(() => {
    if (fetchData) {
      setFetchData(false)
      sendRequest(url, crudConstNames.get)
    }
  }, [fetchData])

  const formattedPricesQuotation = formattedSaleListTotalPrices(quotations)

  return (
    <Paper
      elevation={0}
      sx={{
        p: 1,
        mt: 2,
        mr: 2,
        backgroundColor: '#ddd'
      }}>
      <EasyTable headers={headers} data={formattedPricesQuotation} actions={actions} />
      <ConfirmationModal
        isOpened={isOpened}
        closeModalHandler={handleCloseModal}
        confirmHandler={handleDestroy}
        titleContent={t('destroy_title', { ns: 'quotation' })}
        bodyContent={t('destroy_body', { ns: 'quotation' })}
        confirmContent={t('delete', { ns: 'translation' })}
        confirmButtonType={'error'}
        confirmParams={activeQuotationId}
      />
    </Paper>
  )
}
