import { createSlice } from '@reduxjs/toolkit'

import { bidFromAdSpace } from '../utils/bid/bidFromAdSpace'
import { calculateLeasingTotal } from '../utils/bid/calculateLeasingTotal'
import { getBaseTotalPriceByAttribute } from '../utils/bid/getBaseTotalPriceByAttribute'
import { getDurationInMonths } from '../utils/bid/getDurationInMonths'
import { getPriceByAttribute } from '../utils/bid/getPriceByAttribute'
import { getPriceByTotals } from '../utils/bid/getPricesByTotals'
import { getTotalPrices } from '../utils/bid/getTotalPrices'

/*
  bids: [
    {
      id: 1,
      ad_space_id: 1,
      quantity: 1,
      total_leasing_price: 0,
      production_price: 0,
      installation_price: 0,
      total_sum_price: 0,
      start_date: '2021-01-01',
      end_date: '2021-01-31',
      _destroy: false
    }
  ]
*/
const initialState = {
  bids: [],
  length: 0,
  totalLeasingPrice: 0,
  totalProductionPrice: 0,
  totalInstallationPrice: 0,
  totalSumPrice: 0,
  packagePricing: false,
  currentTab: '',
}

export const bidsCartSlice = createSlice({
  name: 'bidsCart',
  initialState,
  reducers: {
    addBid: (state, action) => {
      const adSpace = action.payload
      const bid = bidFromAdSpace(adSpace)
      state.bids.push(bid)
      state.bidsCount += 1
      if (state.packagePricing) {
        const newBids = getPriceByTotals(state)
        state.bids = newBids.bids
      } else {
        Object.assign(state, getTotalPrices(state))
      }
    },
    addBids: (state, action) => {
      const adSpaces = action.payload
      const bids = adSpaces.map((adSpace) => bidFromAdSpace(adSpace))
      state.bids.push(...bids)
      state.bidsCount += bids.length
      if (state.packagePricing) {
        const newBids = getPriceByTotals(state)
        state.bids = newBids.bids
      } else {
        Object.assign(state, getTotalPrices(state))
      }
    },
    removeBid: (state, action) => {
      const { index } = action.payload
      state.bids[index]._destroy = true
      state.bids[index].quantity = 0
      state.bids[index].total_leasing_price = 0
      state.bids[index].production_price = 0
      state.bids[index].installation_price = 0
      if (state.packagePricing) {
        const newBids = getPriceByTotals(state)
        state.bids = newBids.bids
      } else {
        Object.assign(state, getTotalPrices(state))
      }
    },
    updateBid: (state, action) => {
      let bid = action.payload.bid
      const { index } = action.payload
      bid = { ...state.bids[index], ...bid }
      if (state.packagePricing) {
        const { total_leasing_price, quantity, duration, duration_unit } = bid
        bid.leasing_price = quantity > 0 ? total_leasing_price / quantity / getDurationInMonths(duration_unit, duration) : 0
      } else {
        bid.total_leasing_price = calculateLeasingTotal(bid)
      }
      state.bids[index] = bid
      Object.assign(state, getTotalPrices(state))
    },
    resetOriginalPrice: (state, action) => {
      const bids = action.payload
      bids.forEach((bid, index) => {
        state.bids[index].leasing_price = bid.ad_space_leasing_price || 0
        state.bids[index].production_price = bid.ad_space_production_price || 0
        state.bids[index].installation_price = bid.ad_space_installation_price || 0
        state.bids[index].total_leasing_price = calculateLeasingTotal(bid)
      })
      Object.assign(state, getTotalPrices(state))
    },
    updatePackagePrice: (state, action) => {
      const { bidsCart, attributes } = action.payload
      const key = Object.keys(attributes)[0]
      const totalPrice = attributes[key]
      const { baseTotalPrice, attributeName, isBaseTotalZero } = getBaseTotalPriceByAttribute(key, bidsCart.bids, totalPrice)

      Object.assign(state, attributes)
      bidsCart.bids.forEach((bid, index) => {
        if (bid.quantity > 0) {
          const newValues = getPriceByAttribute(
            bid,
            attributeName,
            totalPrice,
            baseTotalPrice,
            isBaseTotalZero
          )
          Object.assign(state.bids[index], newValues)
        } else {
          Object.assign(state.bids[index], bid)
        }
      })
      state.totalSumPrice = state.totalLeasingPrice + state.totalProductionPrice + state.totalInstallationPrice
    },
    setBids: (state, action) => {
      const bids = action.payload
      state.bids = bids
      state.bidsCount = bids.length
      Object.assign(state, getTotalPrices(state))
    },
    setTotalsFromQuotation: (state, action) => {
      Object.assign(state, action.payload)
    },
    setPackagePricing: (state, action) => {
      state.packagePricing = action.payload
    }
  }
})

export const { addBid, addBids,removeBid, updateBid, updatePackagePrice, resetOriginalPrice, setBids, setTotalsFromQuotation, setPackagePricing } = bidsCartSlice.actions
export default bidsCartSlice.reducer
