import axios from 'axios'
import { useEffect, useState } from 'react'

import { getAuthorizationCode } from '../../utils/authorization'
import { severities } from '../../utils/enums/notifications/notificationsSeverities'
import notify from '../../utils/notifications/notify'
import { crudConstPath } from '../../utils/enums/crudConst'

const AD_SPACES_URL = crudConstPath.adSpaces.detailed_index

export const useAdSpacesFetcher = (adSpacesIds, params = {}) => {
  const [adSpaces, setAdSpaces] = useState([])
  const fetchAdSpaces = async () => {
    try {
      const response = await axios.get(AD_SPACES_URL, {
        params: { ids: adSpacesIds, ...params },
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${getAuthorizationCode()}`
        }
      })

      const { data } = response.data
      setAdSpaces(data)
    } catch (error) {
      notify(error.message, severities.error)
    }
  }

  useEffect(() => {
    if (adSpacesIds.length === 0) return
    fetchAdSpaces()
  }, [adSpacesIds])

  return { adSpaces }
}
