import { useEffect, useState } from 'react';

import { normalizeIconName } from '../utils/utils';

const defaultIcon = `${process.env.PUBLIC_URL}/assets/markers/marker-letrero-publicitario.png`;

const getMarkerIconUrl = iconName => new Promise(resolve => {
  const markerName = normalizeIconName(iconName)
  const iconUrl = `${process.env.PUBLIC_URL}/assets/markers/${markerName}`;
  const img = new Image();
  img.onload = () => resolve(iconUrl);
  img.onerror = () => resolve(defaultIcon);
  img.src = iconUrl;

});

const useMarkerIconLoader = markers => {

  const [markerIcons, setMarkerIcons] = useState({});

  useEffect(() => {
    if (markers?.length === 0) return;
    if (!Array.isArray(markers)) return;

    const loadIcons = async () => {
      const iconPromises = markers.map(marker => getMarkerIconUrl(marker.icon));
      const icons = await Promise.all(iconPromises);
      setMarkerIcons(Object.fromEntries(icons.map((icon, index) =>
      {
        return ([markers[index].item_ids[0], icon])
      }
      )));
    };

    loadIcons();
  }, [markers]);

  return markerIcons;
};

export default useMarkerIconLoader;
